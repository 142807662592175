import React from "react"

import { FormattedMessage, useIntl } from "react-intl"

import SEO from "~/components/Seo/Seo"
import { Loader } from "~/components/ui"
import { apiPublicUserToPublicUser } from "~/models/PublicUser"
import { usePublicProfileQuery } from "~/state/api/publicProfile"
import ProfilePage from "~/templates/Profile/ProfilePage/ProfilePage"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"
import { ChefclubLocale } from "~/utils/locales"

import css from "./PublicProfile.module.scss"

interface PublicProfileRouteProps extends TemplateDefaultPropTypes {
  userId: string
}
// userId prod: 387f1bb4-47b9-42d2-98cb-f360b2d4e316 (Romain)
// usedId dev:  84d9901c-1ec1-48fe-96f9-b86bd61a21f4 (Julien)
// usedId dev:  69d1ff34-e2d1-4d9a-96e4-a735fac2d874 (Nicolas)
function PublicProfile(props: PublicProfileRouteProps) {
  const intl = useIntl()
  const {
    data: userData,
    refetch,
    ...userState
  } = usePublicProfileQuery({
    userId: props.userId,
    lang: intl.locale as ChefclubLocale,
  })
  const user = userData ? apiPublicUserToPublicUser(userData.user) : null

  const isCreator = user?.userTypes.some(ut => ut === "creator")

  const meta = !isCreator ? [{ name: "robots", content: "noindex" }] : []
  const link = isCreator
    ? [
        {
          rel: "canonical",
          href: `https://www.chefclub.tv/fr/profil/u/${user?.username}`,
        },
      ]
    : []

  if (userState.isLoading && !user) {
    return <></>
  }

  return (
    <>
      <SEO
        title={user?.username || "Anonym"}
        location={props.location}
        meta={meta}
        link={link}
      />

      {user ? (
        <div className={css.root}>
          {userState.isLoading ? (
            <div className={css.pageLoaderWrap}>
              <Loader />
            </div>
          ) : (
            <ProfilePage user={user} privacy="Public" />
          )}
        </div>
      ) : (
        <div className={css.userNotFound}>
          <FormattedMessage id="profile/text:this-user-does-not-exist" />
        </div>
      )}
    </>
  )
}

export default withTemplate(PublicProfile)
